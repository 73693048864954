<template>
  <main>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-12-mobile is-6-tablet is-5-desktop">
          <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
            <ul>
              <sqr-crumb name="broker" :bid="bid">{{$t('breadcrumb_broker', broker)}}</sqr-crumb>
              <sqr-crumb name="search-index" :bid="bid" label="breadcrumb_search_index"/>
            </ul>
          </nav>
          <div class="field">
            <sqr-button
              label="search_index_relations"
              @click="relationsIndex()"
              is-fullwidth
              :is-loading="relationsIndexing"
            />
          </div>
          <sqr-error-note class="field" :error="relationsIndexError"/>
          <div class="field">
            <sqr-button
              label="search_index_policies"
              @click="policiesIndex()"
              is-fullwidth
              :is-loading="policiesIndexing"
            />
          </div>
          <sqr-error-note class="field" :error="policiesIndexError"/>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import broker from './broker'
import { mapActions } from 'vuex';
import { isNil } from 'ramda';
import buildRef from '@/utils/buildRef';

export default {
  name: 'SearchIndex',
  mixins: [broker],
  data () {
    return {
      relationsIndexing: false,
      relationsIndexError: null,
      policiesIndexing: false,
      policiesIndexError: null
    };
  },
  methods: {
    ...mapActions('relationsIndex', {relationsRebuild: 'rebuild'}),
    ...mapActions('policiesIndex', {policiesRebuild: 'rebuild'}),
    async relationsIndex () {
      try {
        this.relationsIndexing = true;
        this.relationsIndexError = null;
        const res = await buildRef(['brokers', this.bid, 'relations']).get();
        const docs = res.docs.map(snap => ({...snap.data(), id: snap.id}));
        this.relationsRebuild({
          bid: this.bid,
          type: 'relation',
          props: ['company', 'givenName', 'familyName', 'birthDate', 'archived'],
          docs
        });
        console.log('indexing', docs.length);
        console.log('relations with issues', docs.filter(d => isNil(d.updated)).map(d => d.id));
      } catch (error) {
        this.relationsIndexError = error;
      } finally {
        this.relationsIndexing = false;
      }
    },
    ...mapActions('policiesIndex', {policiesRebuild: 'rebuild'}),
    async policiesIndex () {
      try {
        this.policiesIndexing = true;
        this.policiesIndexError = null;
        // .limit(10)
        const res = await buildRef(['brokers', this.bid, 'policies']).get();
        const docs2 = res.docs.map(snap => ({...snap.data(), id: snap.id}));
        const docs = docs2.map(doc => ({...doc, numberAlt: doc.number && doc.number.replace(/\W/g, '') || ''}));
        console.log('indexing', docs.length);
        console.log('policies with issues', docs.filter(d => isNil(d.insurerName)).map(d => d.id));
        this.policiesRebuild({
          bid: this.bid,
          type: 'policy',
          props: ['relationId', 'number', 'numberAlt', 'archived'],
          docs
        });
      } catch (error) {
        this.policiesIndexError = error;
      } finally {
        this.policiesIndexing = false;
      }
    }
  }
}
</script>
